import Axios from "axios";

// const API_ENDPOINT = `https://api.dragonball99.me`;
// const API_ENDPOINT = `https://api.auto4bet.co`;
// const API_ENDPOINT = `https://api.super4bet.com`;
// const API_ENDPOINT = `https://api.nycgold789.co`;
// const API_ENDPOINT = `https://api.ufobet788.com`;
const API_ENDPOINT = `https://api.dragonheng29.com`;
// const API_ENDPOINT = `https://api.vegasvip88.com`;
// const API_ENDPOINT = `https://api.copa65.co`;
// const API_ENDPOINT = `https://api.betwin999.co`;
// const API_ENDPOINT = `https://api.betremix77.bet`;
// const API_ENDPOINT = `https://api.spinxbet24.co`;
// const API_ENDPOINT = `https://api.caviar66.com`;
// const API_ENDPOINT = `https://api.meetang168.club`;

export const HTTP = Axios.create({
  baseURL: API_ENDPOINT,
});